@use 'global-styles/marble/base/base.scss';

.wrapper {
	display: inline-flex;
	margin-left: 4px;
}

.icon {
	.wrapper:focus-within &,
	.wrapper:hover &,
	.wrapper:focus & {
		background-color: var(--color-focus-state-background);
	}
}

.modal {
	bottom: calc(100% - 6px);
	display: none;
	height: auto;
	position: absolute;
	right: calc(50%);
	transform: translateX(50%);
	white-space: normal;
	width: 200px;
	@media (min-width: base.$breakpoint-m) {
		width: 250px;
	}

	.wrapper:focus-within &,
	.wrapper:hover &,
	.wrapper:focus & {
		display: block;
	}
}

.text {
	background: var(--color-white);
	border-radius: 4px;
	box-shadow: base.$shadow-active;
	cursor: text;
	font-size: var(--textsize-xs);
	line-height: 1.25;
	margin: var(--spacing-micro);
	padding: var(--spacing-micro);

	&::after {
		background: var(--color-white);
		bottom: 0;
		content: ' ';
		height: 1em;
		left: 50%;
		position: absolute;
		transform: rotate(45deg) translateX(-50%);
		width: 1em;
	}

	a {
		margin-left: 2px;
		white-space: nowrap;

		&:hover { /* stylelint-disable-line */
			text-decoration: none;
		}
	}

	div {
		margin-bottom: var(--spacing-xs);
	}

	em {
		display: block;
		font-style: normal;
		font-weight: 600;
	}
}
