@use 'global-styles/marble/base/sizes.scss';

$desktop-image-height: 134px;
$desktop-image-width: 201px;
$mobile-card-width: 80vw;
$mobile-image-height: 53vw;
$desktop-compact-card-width: 250px;

.eventCard {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	position: relative;
	row-gap: var(--spacing-xxs);
	width: $mobile-card-width;


	@media only screen and (min-width: sizes.$breakpoint-m) {
		column-gap: var(--spacing-micro);
		flex-direction: row;
		overflow-x: visible;
		padding: ver(--spacing-xs);
		width: auto;
	}
}

.eventModeCard {
	margin: 0 var(--spacing-component-margin);
	width: auto;

	@media screen and (min-width: sizes.$breakpoint-m) {
		margin: 0;
	}
}

.imageWrapper {
	aspect-ratio: 3 / 2;
	background-color: var(--color-grey-050);
	display: flex;
	height: $mobile-image-height;
	position: relative;
	width: $mobile-card-width;

	@media only screen and (min-width: sizes.$breakpoint-m) {
		flex-basis: $desktop-image-width;
		height: $desktop-image-height;
		margin-top: var(--spacing-xs);
		max-height: $desktop-image-height;
		min-width: $desktop-image-width;
	}

	&:hover {
		background-color: var(--color-grey-100);
	}

	img {
		margin: 0 auto;
		object-fit: cover;
		object-position: center center;
	}
}

.eventModeImageWrapper {
	width: 100%;
	
	@media screen and (min-width: sizes.$breakpoint-m) {
		width: $desktop-image-width;
	}
}

.cardImage {
	max-width: 100%;

	@media only screen and (min-width: sizes.$breakpoint-m) {
		max-width: $desktop-image-width;
	}
}

.infoContainer {
	@media only screen and (min-width: sizes.$breakpoint-m) {
		flex-grow: 1;
		padding: var(--spacing-xs);
	}
}

.title {
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	font-size: var(--textsize-m);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
	overflow: hidden;
	text-overflow: ellipsis;
	/* stylelint-disable-next-line order/properties-alphabetical-order */
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

// This clamps the description to 3 lines because we have no control over the input data
// and some of it is quite lengthy.
.description {
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	margin-bottom: var(--spacing-micro);
	overflow: hidden;
	text-overflow: ellipsis;
	/* stylelint-disable-next-line order/properties-alphabetical-order */
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	
}

.timeAndPlace {
	color: var(--color-grey-700);
	column-gap: var(--spacing-micro);
	display: flex;
	flex-wrap: wrap;
	font-size: var(--textsize-xxs);
	font-weight: 500;
	justify-content: flex-start;
	margin-bottom: var(--spacing-micro);
	text-transform: uppercase;
}

// styles that force this card to go into mobile view (for a shelf container)
.compact {
	flex-direction: column;

	@media only screen and (min-width: sizes.$breakpoint-m) {
		width: $desktop-compact-card-width;
	}

	.infoContainer {
		padding: 0;
	}

	.imageWrapper {
		flex-basis: auto;
		margin-top: 0;
		max-height: unset;
		min-width: unset;

		@media only screen and (min-width: sizes.$breakpoint-m) {
			height: auto;
			width: 100%;
		}
	}

	.cardImage {
		@media only screen and (min-width: sizes.$breakpoint-m) {
			max-width: 100%;
		}
	}
}
